#
# Meeting Engine :: External JS
#
$.extend true, $.me, {
  seoOpenMap: null
  seoRoute:
    from_adress:
      lat: null
      lng: null

  clubs_map_markers: ->
    opts = $('div[data-me-gmap]').data 'me-gmap'

    $.me.seoOpenMap = $('div[data-me-gmap]').openMap(
      center:
        lat: opts.center.lat
        lng: opts.center.lng).show().markers(opts.markers)

  actionBarObserver: ->
    container = $('#club-site')
    header = $('.club-site-header')
    headerHeight = header.outerHeight()
    headerTop = header.offset().top
    links = header.find('a[data-me-scroll-link]')

    $(window).scroll ->
      if $(document).scrollTop() >= headerTop and !container.hasClass('fixed')
        container.addClass 'fixed'
      else if $(document).scrollTop() < headerTop && container.hasClass('fixed')
        container.removeClass 'fixed'
      return

    $('.club-site-header').on 'click', 'a[data-me=scroll-link]', (e) ->
      e.preventDefault();
      sectionId = this.href.split("#")[1]
      sectionTop = $('#'+sectionId).offset().top
      topTotal = sectionTop-headerHeight

      $('html,body').animate {scrollTop : topTotal}, 'slow'
}

# Page loaded
$(document).on 'turbolinks:load', ->
  console.log "Turbolink load"
  I18n.locale = document.documentElement.lang

  # Flash message
  $.me.flashMessage.reset().createFromDom()

  # Send Browser Fingerprint
  # $.me.fingerprint.init()

  # Remove cookie disclaimer
  $(document).on 'click', '#cookie-disclaimer button', (e) ->
    e.preventDefault()
    expires = new Date()
    expires.setTime expires.getTime() + (365 * 86400 * 1000)
    document.cookie = "cookie-disclaimer=1;expires=#{expires.toUTCString()};path=/";
    $('#cookie-disclaimer').remove()

  $('[data-toggle]').on 'click', (e) ->
    node = $(e.currentTarget.dataset.toggle)
    state = node.hasClass('is-hidden')
    node.toggleClass 'is-hidden', !state
    # $('html').toggleClass 'no-scroll', state

  $('[data-password] > button').on 'click', (e) ->
    input = $(e.currentTarget).prev()
    input.attr 'type', (if input.attr('type') is 'text' then 'password' else 'text')

  $('[data-accordeon]').on 'click', (e) -> $(e.currentTarget).parent().toggleClass('is-close')

  # Hack to open registration
  urlParams = new URLSearchParams(window.location.search)
  if urlParams.has 'register'
    console.log "register"
    setTimeout ->
      $('[data-register-form] button').click()
    , 100

  # OLD
  $('[data-close-panel]').on 'click', (e) ->
    $('body').toggleClass 'mobile--is-open'
    return

  # Execute controller.action for loaded page
  $.me.actionCallback $('body').data('me-controller'), $('body').data('me-action')

  if window.dataLayer
    window.dataLayer.push
      event: 'pageView',
      pagePath: document.location.pathname + document.location.search

  # Switch Header
  switchHeader = ->
    html = document.documentElement
    target = document.querySelector("[data-target-switch-header]")
    trigger = document.querySelector("[data-trigger-switch-header]")
    if not trigger
       return

    offset = trigger.offsetHeight + target.offsetHeight * 2

    update = (entries) ->
      entry = entries[0]
      if not entry.isIntersecting
        html.setAttribute "data-switch-header", "has-background"
      else
        html.removeAttribute "data-switch-header"

    observer = new IntersectionObserver update,
      rootMargin: "-#{offset}px 0px 0px 0px"

    observer.observe trigger

  switchHeader()
