import Swiper, { Navigation, Pagination } from 'swiper'

$.extend true, $.me,
  external:
    club_evenings:
      index_act: ->
        $.me.external.clubs.initSearch()
        $.me.external.clubs.renderClusteredMap @clubEveningPopupRenderer
        Swiper.use([Navigation, Pagination]);
        new Swiper('.js-slider-classic', {
          pagination: {
            el: ".js-slider-classic-pagination",
            clickable: true,
          },
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 15,
        })

      show_act: ->
        $.me.external.clubs.renderClusteredMap @clubEveningPopupRenderer

        new Swiper('#club-evenings-slider', {
          pagination: {
            el: "#clubs-slider-pagination",
            clickable: true
          },
          slidesPerView: 1,
          spaceBetween: 15,
          breakpoints: {
            990: {
              slidesPerGroup: 3,
              slidesPerView: 3,
            },
          }
        })

      #Todo: i18n
      clubEveningPopupRenderer: (marker) ->
        """
        <div class="map-popup__content">
          <div class="map-popup__title">#{marker.name}</div>
          <div class="map-popup__subtitle">#{marker.date}</div>
          <div class="map-popup__text">
            #{marker.address}<br>
            #{marker.zip} #{marker.city} (#{marker.country})<br>
            Tel : #{marker.phone}
          </div>
          <div class="map-popup__cta">
            <a href="#{marker.url}" class="button pink rounded">
              Découvrir la soirée
            </a>
          </div>
        </div>
        """
