import Rails from '@rails/ujs'

$.extend true, $.me,
  account:
    init: ->
      $('input[name="registration[user_type_id]"]').change (e) ->
        $('[data-user-type]').attr 'data-user-type', $(e.currentTarget).val()


      $('[data-city-by-zip=zip-code]').selectCityByZip()

      $('[data-password] > button').click (e) ->
        e.preventDefault()
        input = $(e.currentTarget).prev()
        input.attr 'type', (if input.attr('type') is 'text' then 'password' else 'text')


    users:
      new_act: ->
        $.me.account.init()

        $(document).on 'me:domChanged', (e) ->
          $.me.account.init() if $(e.target).find('[data-me=signup-form]').exists()
          $('#disclaimer-button').click (e) ->
            e.preventDefault()
            day = $('#days').val()
            month = $('#months').val()
            year = $('#years').val()
            birth_date = new Date(year, month, day)

            birth_date.setFullYear(birth_date.getFullYear() + 18)

            if year &&  birth_date <= new Date() 
              $('#disclaimer-button').attr("data-modal", "close")

            else if year == ""
              $('#disclaimer-error-message').removeClass('display-none')
            else 
              location.href = 'https://www.google.fr'

      create_act: ->
        $.me.account.init()

        step      = parseInt $('form[data-me="signup-form"').data('step')
        max_step  = parseInt $('form[data-me="signup-form"').data('max-step')

        if step == max_step
          redirect = $('form[data-me="signup-form"').data('redirect')
          location.href = redirect

        $('[data-username-suggestion]').click (e) ->
          e.preventDefault()
          $('#registration_username').val($(e.target).data('username-suggestion')).trigger('change')

# External :: End of account_users
